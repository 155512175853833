import { baseEnvironmentProd } from 'src/environments/baseEnvironment.prod';

export const environment = {
  ...baseEnvironmentProd,
  organizationPrefix: 'NER',
  organizationUUID: '9549311d-5b5e-4946-84a9-e76ec2755291',
  activeLanguage: 'it-IT',
  tableStructureUUID: '',
  multipleAdminCommunity: true,
  requiredField: {
  },
  systemToken: '',
  htmlFields: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }],
    ['blockquote'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub'}, { script: 'super' }, 'link'],
    [{ list: 'ordered'}, { list: 'bullet' }, { align: [] }],
    ['clean']
  ],

  basicConfiguration: {
    htmlEditorMaxLength: {
      active: false,
      where: [
        {
          name: 'product',
          value: 270
        }
      ]
    }
  },
  googleAPIKey: '',
  manageTemplates: false,
  manageUserFilter: false,

  productEditorTabIcons: [
    'r',
    'z',
    'M',
    'x',
    'm'
  ],
  pwaThemeColor: "rgba(0, 72, 205, 1.0)"
};
